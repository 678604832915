.home-content {
    overflow: scroll;
    font-weight: 500;
    z-index: 100;
}

li {
    list-style-type: none;
    color: #1e1d1d;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

ul {
    background-color: #efd189;
}

h1 {
    font-family: 'Poltawski Nowy', serif;
    z-index: 100;
}

h3 {
    font-family: 'Poltawski Nowy', serif;
    font-weight: 600;
    z-index: 100;
}

h4 {
    font-weight: 400;
    font-family: 'Poltawski Nowy', serif;
    z-index: 100;
}

.home-desc {
    background-color: #E4A50F;
    z-index: 100;
}

.services-offered {
    z-index: 100;
    background-color: #E4A50F;
}