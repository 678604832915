.bio {
    z-index: 100;
    background-color: #E3A50F;
    margin: 10px 5px;
}

.about {
    z-index: 100;
}

.img-thumbnail {
    background-color: #E3A50F;
}