body {
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FBF1D8;
  margin-top: 100px;
  margin-bottom: 150px;
  z-index: 100;
}

.btn-primary {
  border: none;
  background-color: #9e4618;
  color: #efd189;
  font-family: 'Poltawski Nowy', serif;
  font-size: larger;
  z-index: 100;
}

.h1 {
  font-family: 'Poltawski Nowy', serif;
  color: #1e1d1d;
  z-index: 100;
}

.h3 {
  font-family: 'Poltawski Nowy', serif;
  color: #1e1d1d;
  z-index: 100;
}

.App {
  text-align: center;
  background-color: #FBF1D8;
}

.App-header {
  background-color: #FBF1D8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1e1d1d;
  z-index: 100;
}

.App-link {
  color: #9e4618;
}

.btn-primary:hover {
  background-color: #9e4618;
  color: #E5DCC5;
}

.nav {
  background-color: #E5DCC5;
}

.nav-link {
  color: #1e1d1d;
  font-family: 'Montserrat', sans-serif;
  margin-left: 13px;
}

.nav-link:hover {
  color: #9e4618;
}

.nav-pills .nav-link.active {
  background-color: #030203;
  color: #E5DCC5;
}

.nav-link.active:hover {
  background-color: #E5DCC5;
}

.navbar-toggler {
  color: #9e4618;
}

.navbar {
  background-color: #E3A50F;
}

p {
  text-align: left;
  background-color: #efd189;
  color: #1e1d1d;
  z-index: 100;
}