a {
    font-weight: 900;
    color: #9e4618;
}

label {
    font-family: 'Montserrat', sans-serif;
    font-size: medium;
}

p {
    text-align: center;
}

.input-group {
    z-index: 100;
}

input {
    z-index: 100;
}

.contact-form {
    z-index: 100;
}